import { ReactElement, useState } from "react";
import { Box, Button, Card, CardContent, CardHeader, Divider, Grid, Typography, TextField, Stack } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Subscription } from "../../common/api/chargify/models/subscription";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import MainRouteConsts from "../MainRouteConsts";
import { StateChip } from "./StatusChip";
type EditSubscriptionDetailsCardProps = {
  subscription: Subscription;
  onUpdate: (activatedDate: Date | null, expiresDate: Date | null) => void;
  updating: boolean;
};

export const EditSubscriptionDetailsCard = ({
  subscription,
  updating,
  onUpdate,
}: EditSubscriptionDetailsCardProps): ReactElement => {
  const [expiresAt, setExpiresAt] = useState<Date | null>(() =>
    subscription.expires_at ? new Date(subscription.expires_at) : null
  );
  const [activatedAt, setActivatedAt] = useState<Date | null>(() => new Date(subscription.activated_at));

  return (
    <Card>
      <CardHeader
        subheader={`${subscription.id} for ${subscription.customer.organization}`}
        title='Subscription'
        action={<StateChip state={subscription.state} />}
      />
      <Divider />
      <CardContent>
        <Grid container spacing={4} wrap='wrap'>
          <Grid item xs={12} sm={12}>
            <Typography color='textPrimary' gutterBottom variant='subtitle2'>
              Product name
            </Typography>
            <Typography color='textSecondary' gutterBottom variant='body1'>
              {subscription.product.name}
            </Typography>
          </Grid>
          <Grid
            item
            md={6}
            sm={6}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
            xs={12}
          >
            <Typography color='textPrimary' gutterBottom variant='subtitle2'></Typography>

            <DesktopDatePicker
              label='Activated at'
              value={activatedAt}
              onChange={(newValue) => {
                setActivatedAt(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
          <Grid
            item
            md={6}
            sm={6}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
            xs={12}
          >
            <Typography color='textPrimary' gutterBottom variant='subtitle2'></Typography>
            <DesktopDatePicker
              label='Expires at'
              value={expiresAt}
              // minDate={new Date("2017-01-01")}
              onChange={(newValue) => {
                setExpiresAt(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          p: 2,
        }}
      >
        <Stack spacing={1} direction='row'>
          <Button color='secondary' variant='outlined' href={MainRouteConsts.Subscriptions}>
            Cancel
          </Button>
          <LoadingButton
            color='primary'
            variant='contained'
            loading={updating}
            onClick={() => onUpdate(activatedAt, expiresAt)}
          >
            Update
          </LoadingButton>
        </Stack>
      </Box>
    </Card>
  );
};
