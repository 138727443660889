import { ReactElement, useState } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { UserInfo } from "../models/UserInfo";
import { UserAvatar } from "./UserAvatar";
import { Menu, MenuItem, ListItemIcon, Box, Divider, IconButton, Typography } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import Logout from "@mui/icons-material/Logout";

export const UserMenu = (): ReactElement => {
  const { instance, accounts } = useMsal();
  const account = accounts[0];
  const userInfo = { name: account.name, email: account.username } as UserInfo;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  };

  return (
    <>
      <IconButton onClick={handleClick} size='small' sx={{ ml: 2 }}>
        <UserAvatar userInfo={userInfo}></UserAvatar>
      </IconButton>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Box sx={{ paddingLeft: 2, paddingRight: 2, paddingBottom: 1, textAlign: "end" }}>
          <Typography variant='h4'>
            {/* <ListItemIcon>
              <Person fontSize='small' />
            </ListItemIcon> */}
            {userInfo.name}
          </Typography>
          <Typography variant='h5'>
            {/* <ListItemIcon>
              <Email fontSize='small' />
            </ListItemIcon> */}
            {userInfo.email}
          </Typography>
        </Box>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize='small' />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};
