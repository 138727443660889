export class ChargifySettings {
  private static apiUrl: string;
  private static apiKey: string;

  public static getApiUrl(): string {
    if (!this.apiUrl) {
      const domain = this.getSubDomain();
      //this.apiUrl = `https://${domain}.chargify.com/`;
      this.apiUrl = `https://draycir-chargify-proxy-functions.azurewebsites.net/chargify-api/${domain}/`;
    }
    return this.apiUrl;
  }

  public static getSubDomain(): string {
    const domain = process.env.REACT_APP_CHARGIFY_DOMAIN || "";
    return domain;
  }

  // public static getApiKey(): string {
  //   if (!this.apiKey) {
  //     this.apiKey = process.env.REACT_APP_CHARGIFY_APIKEY || "";
  //   }
  //   return this.apiKey;
  // }
}
