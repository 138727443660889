import { ReactElement } from "react";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import { TypeColumn, TypeDataSource, TypeSingleSortInfo } from "@inovua/reactdatagrid-community/types";
import { TypeFilterValue } from "@inovua/reactdatagrid-community/types";
import { TypeOnSelectionChangeArg } from "@inovua/reactdatagrid-community/types/TypeDataGridProps";
import "@inovua/reactdatagrid-community/index.css";

export const defaultGridStyle = {
  minHeight: 500,
  minWidth: 800,
};

export const defaultPageSize = 10;

export declare type TypeColumnRender<T> = {
  data: T;
  cellProps: unknown;
  rowIndex: number;
  rowSelected: boolean;
};

// Default props for LoadData function
export interface LoadDataProps {
  skip: number;
  limit: number;
  sortInfo: TypeSingleSortInfo;
  //  groupBy: any;
  filterValue: TypeFilterValue;
}

// Page parameters
export interface PageParameters {
  page: number;
  itemsPerPage: number;
  sort: string;
}

/*
 * Creates the page parameters from the data grid details
 */
export function createPageParameters(skip: number, limit: number, sortInfo?: TypeSingleSortInfo): PageParameters {
  const page = skip / limit + 1;
  let sort = "";
  if (sortInfo) {
    sort = (sortInfo.dir === -1 ? "-" : "") + sortInfo.name;
  }
  return { page, itemsPerPage: limit, sort } as PageParameters;
}

interface DataGridProps<T> {
  idProperty: string;
  columns: TypeColumn[];
  style?: {
    [key: string]: string | number;
  };
  dataSource: TypeDataSource;
  defaultFilterValue?: TypeFilterValue;
  onSelectionChange?: (selection: T) => void;
}

DataGrid.defaultProps = {
  style: defaultGridStyle,
};

export function DataGrid<T>({
  idProperty,
  columns,
  style,
  dataSource,
  defaultFilterValue,
  onSelectionChange,
  ...rest
}: DataGridProps<T>): ReactElement {
  function handleSelectionChanged(selection: TypeOnSelectionChangeArg) {
    if (onSelectionChange && selection.data) {
      onSelectionChange(selection.data as unknown as T);
    }
  }

  return (
    <ReactDataGrid
      idProperty={idProperty}
      columns={columns}
      style={style}
      pagination
      defaultLimit={defaultPageSize}
      dataSource={dataSource}
      defaultFilterValue={defaultFilterValue}
      enableSelection={onSelectionChange ? true : false}
      onSelectionChange={handleSelectionChanged}
      {...rest}
    />
  );
}
