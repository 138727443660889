import { ReactElement } from "react";
import { Box, Container, Typography, Breadcrumbs, Link, Skeleton } from "@mui/material";
import { SignInButton } from "../components/auth/SignInButton";
import getProductInfo from "../common/productInfo";
export const UnauthenticatedInfo = (): ReactElement => {
  const productInfo = getProductInfo();
  return (
    <>
      <div
      // sx={{
      //   backgroundColor: "background.default",
      //   minHeight: "100%",
      //   pt: 3,
      // }}
      >
        <Container>
          <h2>{`Welcome to the ${productInfo.productName}`}</h2>
          <SignInButton />
        </Container>
      </div>
    </>
  );
};

export default UnauthenticatedInfo;
