import { ReactElement } from "react";
import { UserInfo } from "../models/UserInfo";
import { Avatar } from "@mui/material";
import { deepOrange } from "@mui/material/colors";

// Returns the initials from a name (stolen from SDC Cloud!)
const getInitials = (displayName: string): string => {
  if (!displayName) {
    return "";
  }

  const parts = displayName.split(" ");
  const characterLimit = 2;
  let initials = "";

  for (const part of parts) {
    if (initials.length === characterLimit) {
      break;
    }

    if (part) {
      initials += part[0];
    }
  }

  return initials;
};

interface UserAvatarProps {
  userInfo: UserInfo;
}

export const UserAvatar = ({ userInfo }: UserAvatarProps): ReactElement => {
  console.log(userInfo);
  return (
    <Avatar sx={{ bgcolor: deepOrange[500] }} alt={userInfo.name}>
      {getInitials(userInfo.name)}
    </Avatar>
  );
};
