import { ReactElement } from "react";
import { loginRequest } from "../../services/adAuth/authConfig";
import { Button } from "@mui/material";

import { useMsal } from "@azure/msal-react";

export const SignInButton = (): ReactElement => {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch((e) => {
      <p>There was an error logging you in: {e}</p>;
    });
  };
  return (
    <Button color='inherit' onClick={() => handleLogin()}>
      Sign In
    </Button>
  );
};
