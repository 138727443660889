import BaseApi from "../baseApi";
import { ChargifySettings } from "./charifySettings";
import { msalInstance } from "../../../services/adAuth/msalInstance";
import { loginRequest } from "../../../services/adAuth/authConfig";

export abstract class BaseChargifyApi extends BaseApi {
  private static authenticationScheme = "Bearer";

  // Returns the base url
  protected get baseUrl(): string {
    return ChargifySettings.getApiUrl();
  }

  // // Returns the api key
  // protected get apiKey(): string {
  //   return ChargifySettings.getApiKey();
  // }

  protected get subDomain(): string {
    return ChargifySettings.getSubDomain();
  }

  // // Our proxy for calling Chargify
  // protected get proxyUrl(): string {
  //   return "http://localhost:7071/api/ChargifyProxy";
  // }

  constructor() {
    super();
  }

  /*
   * Processes the url before executing
   */
  protected processUrl(url: string): string {
    return url + ".json";
  }

  /*
   * Encodes the url before executing
   */
  protected encodeUrl(url: string): string {
    return url;
    //return `${this.proxyUrl}?domain=${this.subDomain}&url=${encodeURIComponent(url)}`;
  }

  /*
   * Builds the URL and the page parameters
   */
  protected buildUrlPageParams(
    page: number,
    itemsPerPage: number = BaseApi.defaultItemsPerPage,
    sort?: string,
    searchQuery?: string
  ): URLSearchParams {
    const params = new URLSearchParams();
    params.append("page", page.toString());
    params.append("per_page", itemsPerPage.toString());
    // if (sort) params.append("sort", sort);
    if (searchQuery) params.append("q", searchQuery);
    return params;
  }

  // Returns the headers required for the http call
  protected async getHeadersAsync(): Promise<Record<string, string>> {
    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: msalInstance.getAllAccounts()[0],
    });
    const token = response.idToken;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `${BaseChargifyApi.authenticationScheme} ${token}`,
    };
    return headers;
  }
}
