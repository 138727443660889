import { ReactElement } from "react";
import { Typography, Breadcrumbs, Link } from "@mui/material";

export type Crumb = {
  title: string;
  href: string | null;
};

type BreadcrumbBuilderProps = {
  crumbs: Crumb[];
  current: string;
};

export const BreadcrumbBuilder = ({ crumbs, current }: BreadcrumbBuilderProps): ReactElement => {
  return (
    <Breadcrumbs aria-label='breadcrumb'>
      {crumbs.map((crumb, index) => {
        return (
          <Link key={index} underline='hover' color='inherit' href={crumb.href ?? ""}>
            {crumb.title}
          </Link>
        );
      })}
      <Typography color='text.primary'>{current}</Typography>
    </Breadcrumbs>
  );
};
