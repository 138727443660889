import axios, { AxiosInstance, AxiosResponse } from "axios";

export abstract class BaseApi {
  protected static defaultItemsPerPage = 10;

  // Returns the base url
  protected abstract get baseUrl(): string;

  // Returns the headers required for the http call
  //protected abstract getHeaders(): Record<string, string>;

  // Returns the headers required for the http call
  protected abstract getHeadersAsync(): Promise<Record<string, string>>;

  // protected getInstance(): AxiosInstance {
  //   const instance = axios.create({
  //     baseURL: this.baseUrl,
  //   });
  //   instance.defaults.headers = this.getHeaders();
  //   //instance.defaults.withCredentials = true;
  //   return instance;
  // }

  protected async getInstanceAsync(): Promise<AxiosInstance> {
    const instance = axios.create({
      baseURL: this.baseUrl,
    });
    instance.defaults.headers = await this.getHeadersAsync();
    //instance.defaults.withCredentials = true;
    return instance;
  }

  /*
   * Builds the URL and the page parameters
   */
  protected abstract buildUrlPageParams(page: number, itemsPerPage: number, sort?: string, filter?: string): URLSearchParams;

  /*
   * Processes the url before executing
   */
  protected processUrl(url: string): string {
    return url;
  }

  /*
   * Encodes the url before executing
   */
  protected encodeUrl(url: string): string {
    return url;
  }

  protected formatParameters(parameters?: URLSearchParams): string {
    return `${parameters ? `?${parameters.toString()}` : ""}`;
  }

  /*
   * Appends the second set of URLSearchParams to the first
   */
  protected appendSearchParameters(firstParams: URLSearchParams, secondParams?: URLSearchParams): URLSearchParams {
    //   if (!firstParams) throw "firstParams in appendSearchParameters cannot be undefined";
    if (secondParams) secondParams.forEach((value, key) => firstParams.append(key, value));
    return firstParams;
  }

  /*
   * Performs a get
   */
  protected async get<T>(endPoint: string, parameters?: URLSearchParams): Promise<T> {
    const inst = await this.getInstanceAsync();
    try {
      const url = `${this.processUrl(endPoint)}${this.formatParameters(parameters)}`;
      const response = await inst.get<T>(url);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  /*
   * Gets an item
   */
  protected async getItem<T, TId>(endPoint: string, itemId: TId, parameters?: URLSearchParams): Promise<T> {
    const inst = await this.getInstanceAsync();
    try {
      const url = `${this.processUrl(`${endPoint}/${itemId}`)}${this.formatParameters(parameters)}`;
      const response = await inst.get<T>(url);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  /*
   * Returns a page it items
   */
  protected async getPage<T>(
    endPoint: string,
    page: number,
    itemsPerPage: number,
    sort?: string,
    searchQuery?: string,
    otherParameters?: URLSearchParams
  ): Promise<T> {
    const inst = await this.getInstanceAsync();
    const params = this.appendSearchParameters(this.buildUrlPageParams(page, itemsPerPage, sort, searchQuery), otherParameters);
    const url = this.encodeUrl(`${this.processUrl(endPoint)}?${params.toString()}`);
    try {
      const response = await inst.get<T>(url);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  /*
   * Performs a post
   */
  protected async post<T>(endPoint: string, data: T): Promise<void> {
    const inst = await this.getInstanceAsync();
    try {
      await inst.post<T>(endPoint, data);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  /*
   * Performs a post, returning a response
   */
  protected async postWithResponse<TIn, TOut>(endPoint: string, data: TIn): Promise<TOut> {
    const inst = await this.getInstanceAsync();
    try {
      const response = await inst.post<TIn, AxiosResponse<TOut>>(endPoint, data);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  /*
   * Performs a put
   */
  protected async put<T>(endPoint: string, data: T): Promise<void> {
    const inst = await this.getInstanceAsync();
    try {
      await inst.put<T>(endPoint, data);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  /*
   * Performs a put, returning a response
   */
  protected async putWithResponse<TIn, TOut>(endPoint: string, data: TIn): Promise<TOut> {
    const inst = await this.getInstanceAsync();
    try {
      const response = await inst.put<TIn, AxiosResponse<TOut>>(endPoint, data);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  /*
   *Performs a delete
   */
  protected async deleteItem<TId>(endPoint: string, itemId: TId): Promise<void> {
    const inst = await this.getInstanceAsync();
    inst.delete(`${endPoint}/${itemId}`);
  }
}

export default BaseApi;
