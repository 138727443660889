import { ReactElement } from "react";
import { Route, RouteComponentProps } from "react-router-dom";

type ParameterRouteProps = {
  path: string;
  parameters?: string;
  exact?: boolean | undefined;
  component?: React.ComponentType<RouteComponentProps<never>> | React.ComponentType<unknown> | undefined;
};

export const ParameterRoute = ({ path, parameters, exact, component }: ParameterRouteProps): ReactElement => {
  let fullPath = path.trimEnd();
  if (parameters) {
    if (fullPath.endsWith("/") && parameters.startsWith("/")) {
      fullPath += parameters.slice(1);
    } else if (fullPath.endsWith("/") || parameters.startsWith("/")) {
      fullPath += parameters;
    } else fullPath += "/" + parameters;
  }
  return <Route path={fullPath} exact={exact} component={component} />;
};

export default ParameterRoute;
