import { BaseChargifyApi } from "./baseChargifyApi";
import { SiteData } from "./models/stiteData";

export class StatsApi extends BaseChargifyApi {
  private endPoint = "stats";

  public async getSiteData(): Promise<SiteData> {
    return await this.get<SiteData>(this.endPoint);
  }
}
