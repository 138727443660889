import { ReactElement } from "react";
import { Card, CardContent, CardHeader, Divider, Grid, Typography } from "@mui/material";

import { Customer } from "../../common/api/chargify/models/customer";

// interface SubscriptionItemProps {
//   id: string;
// }

type CustomerCardProps = {
  customer: Customer;
};

export const CustomerCard = ({ customer }: CustomerCardProps): ReactElement => {
  if (!customer) return <></>;
  return (
    <Card>
      <CardHeader subheader={`${customer.organization}`} title='Customer' />
      <Divider />
      <CardContent>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <Typography color='textPrimary' gutterBottom variant='subtitle2'>
              Chargify Id
            </Typography>
            <Typography color='textSecondary' gutterBottom variant='body1'>
              {customer.id}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography color='textPrimary' gutterBottom variant='subtitle2'>
              Contact name
            </Typography>
            <Typography color='textSecondary' gutterBottom variant='body1'>
              {customer.first_name} {customer.last_name}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography color='textPrimary' gutterBottom variant='subtitle2'>
              Contact email
            </Typography>
            <Typography color='textSecondary' gutterBottom variant='body1'>
              {customer.email}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography color='textPrimary' gutterBottom variant='subtitle2'>
              Telephone
            </Typography>
            <Typography color='textSecondary' gutterBottom variant='body1'>
              {customer.phone}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
    </Card>
  );
};
