import { ReactElement, useState } from "react";
import { Box, Menu, MenuItem, IconButton, Link } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MainRouteConsts from "../views/MainRouteConsts";

export const PagesMenu = (): ReactElement => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box>
      <IconButton onClick={handleClick} size='large' edge='start' color='inherit' aria-label='menu' sx={{ mr: 2 }}>
        <MenuIcon />
      </IconButton>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        onClick={handleClose}
        //transformOrigin={{ horizontal: "right", vertical: "top" }}
        //anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem>
          <Link underline='none' href={MainRouteConsts.Subscriptions}>
            Subscriptions
          </Link>
        </MenuItem>
      </Menu>
    </Box>
  );
};
