import { ReactElement } from "react";
import { Box, Typography, Toolbar, AppBar } from "@mui/material";
import { PagesMenu } from "./PagesMenu";
import { SignInButton } from "../components/auth/SignInButton";
import { UserMenu } from "./UserMenu";
import { useIsAuthenticated } from "@azure/msal-react";
import getProductInfo from "../common/productInfo";
import SiteVersion from "./SiteVersion";

export const TopBar = (): ReactElement => {
  const isAuthenticated = useIsAuthenticated();
  const productInfo = getProductInfo();
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position='static'>
        <Toolbar>
          {isAuthenticated && <PagesMenu />}
          <Typography variant='h4' component='div' sx={{ flexGrow: 1 }}>
            {productInfo.productName}
          </Typography>
          <SiteVersion />
          <UserOrSignIn />
        </Toolbar>
      </AppBar>
    </Box>
  );
};

const UserOrSignIn = (): ReactElement => {
  const isAuthenticated = useIsAuthenticated();
  return isAuthenticated ? <UserMenu /> : <SignInButton />;
};
