import { FeatureFlags } from "flagged";
import * as featureFlagsInfo from "../assets/data/feature-flags.json";
import { isDevelopmentMode } from "./environmentVariables";

/*
 * Returns the settings for the feature flags
 */
export const getFeatureFlagsSettings = (): FeatureFlags => {
  if (isDevelopmentMode()) return featureFlagsInfo.dev;
  return featureFlagsInfo.production;
};
