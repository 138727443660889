import { Box, Typography } from "@mui/material";
import { ReactElement } from "react";
import getProductInfo from "../common/productInfo";

const SiteVersion = (): ReactElement => {
  const productInfo = getProductInfo();
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Typography component='div' sx={{ flexGrow: 1 }}>
        {productInfo.siteVersion}
      </Typography>
    </Box>
  );
};

export default SiteVersion;
