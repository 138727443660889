import { ReactElement } from "react";
import { Card, CardContent, CardHeader, Divider, Grid, Typography, Chip } from "@mui/material";
import { SiteData } from "../../common/api/chargify/models/stiteData";

type SiteDetailsCardProps = {
  siteData: SiteData;
};

export const SiteDetailsCard = ({ siteData }: SiteDetailsCardProps): ReactElement => {
  return (
    <Card>
      <CardHeader
        subheader={`${siteData.site_name} for ${siteData.seller_name}`}
        title='Site Data'
        action={<Chip label={siteData.site_currency} color='success' />}
      />
      <Divider />
      <CardContent>
        <Grid container spacing={3} wrap='wrap'>
          <CardItemDetail title='Total subscriptions' body={siteData.stats.total_subscriptions} />
          <CardItemDetail title='Active subscriptions' body={siteData.stats.total_active_subscriptions} />
          <CardItemDetail title='Cancelled subscriptions' body={siteData.stats.total_canceled_subscriptions} />
          <CardItemDetail title='Subscriptions created today' body={siteData.stats.subscriptions_today} />
        </Grid>

        {/* <Grid
            item
            md={6}
            sm={6}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
            xs={12}
          >
            <Typography color='textPrimary' gutterBottom variant='subtitle2'></Typography>
            <DesktopDatePicker
              label='Expires at'
              value={expiresAt}
              // minDate={new Date("2017-01-01")}
              onChange={(newValue) => {
                setExpiresAt(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid> */}
      </CardContent>
    </Card>
  );
};

type CardItemDetailProps = {
  title: string;
  body: string | number;
};

export const CardItemDetail = ({ title, body }: CardItemDetailProps): ReactElement => {
  return (
    <Grid item xs={12} sm={4}>
      <Typography color='textPrimary' gutterBottom variant='subtitle2'>
        {title}
      </Typography>
      <Typography color='textSecondary' gutterBottom variant='body1'>
        {body}
      </Typography>
    </Grid>
  );
};
