import { act } from "react-dom/test-utils";
import PageResult from "../PageResult";
import { BaseChargifyApi } from "./baseChargifyApi";
import { Subscription } from "./models/subscription";

export type SubscriptionResult = {
  subscription: Subscription;
};

export class SubscriptionsApi extends BaseChargifyApi {
  private endPoint = "subscriptions";

  public async getSubscription(id: string): Promise<Subscription> {
    const result = await this.getItem<SubscriptionResult, string>(this.endPoint, id);
    return result.subscription;
  }

  public async getSubscriptions(
    page: number,
    itemsPerPage: number,
    sort?: string,
    searchQuery?: string
  ): Promise<PageResult<Subscription>> {
    const result = await this.getPage<SubscriptionResult[]>(this.endPoint, page, itemsPerPage, sort, searchQuery);
    const subscriptions = result.map((item) => item.subscription);
    return { items: subscriptions, page: page, totalItems: 0 } as PageResult<Subscription>;
  }

  public async overrideActivatedExpires(id: string | null, activatedAt: Date, expiresAt: Date | null): Promise<void> {
    const data = {
      subscription: {
        activated_at: activatedAt?.toISOString(),
        expires_at: expiresAt?.toISOString(),
        current_period_starts_at: activatedAt?.toISOString(),
      },
    };
    const inst = await this.getInstanceAsync();
    const url = this.processUrl(`${this.endPoint}/${id}/override`);
    await inst.put(url, data);
  }
}
