import { ReactElement, useEffect, useState } from "react";
import { Box, Container, Link, Skeleton } from "@mui/material";
import MainRouteConsts from "../MainRouteConsts";
import { SiteDetailsCard } from "./SiteStatsCard";
import { SiteData } from "../../common/api/chargify/models/stiteData";
import { StatsApi } from "../../common/api/chargify/statsApi";

export const HomePage = (): ReactElement => {
  const [siteData, setSiteData] = useState<SiteData>();

  const api = new StatsApi();

  useEffect(() => {
    api.getSiteData().then((result) => {
      setSiteData(result);
    });
  }, []);

  return (
    <>
      <Container>
        <h1>Welcome to your dashboard!</h1>
        <Link underline='hover' color='inherit' href={MainRouteConsts.Subscriptions}>
          Subscriptions
        </Link>
      </Container>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          pt: 3,
          pb: 3,
        }}
      >
        <Container>
          {siteData ? <SiteDetailsCard siteData={siteData} /> : <Skeleton variant='rectangular' width={210} height={118} />}
        </Container>
      </Box>
    </>
  );
};

export default HomePage;
