import { ReactElement } from "react";
import "./App.css";
import { ThemeProvider, StyledEngineProvider } from "@mui/material";
import { MainLayout } from "./layout/MainLayout";
import theme from "./theme";
import DateAdapter from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import enLocale from "date-fns/locale/en-GB";
import type {} from "@mui/lab/themeAugmentation";
import { SnackbarProvider } from "notistack";
import { FlagsProvider } from "flagged";
import { getFeatureFlagsSettings } from "./common/featureFlags";

export default App;
function App(): ReactElement {
  const content = (
    <LocalizationProvider dateAdapter={DateAdapter} locale={enLocale}>
      <FlagsProvider features={getFeatureFlagsSettings()}>
        <SnackbarProvider
          maxSnack={5}
          autoHideDuration={4000}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <div className='App'>
            <MainLayout />
          </div>
        </SnackbarProvider>
      </FlagsProvider>
    </LocalizationProvider>
  );

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        {/* <GlobalStyles /> */}
        {content}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
