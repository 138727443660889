import { ReactElement } from "react";
import Chip from "@mui/material/Chip";

type StateChipProps = {
  state: string;
};

const StateMap = new Map<string, string>([
  ["active", "Active"],
  ["canceled", "Cancelled"],
  ["expired", "Expired"],
  ["expired_cards", "Expired Cards"],
  ["on_hold", "On Hold"],
  ["past_due", "Past Due"],
  ["pending_cancellation", "Pending Cancellation"],
  ["pending_renewal", "Pending Renewal"],
  ["suspended", "Suspended"],
  ["trial_ended", "Trial Ended"],
  ["trialing", "Trialing"],
  ["unpaid", "Unpaid"],
]);

export const StateChip = ({ state }: StateChipProps): ReactElement => {
  const label = StateMap.get(state) ?? state;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let colour: any = "default";

  switch (state) {
    case "active":
      colour = "success";
      break;
    case "on_hold":
      colour = "error";
      break;
  }

  return <Chip label={label} color={colour} size='small' />;
};
