import { ReactElement } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { ParameterRoute } from "../components/route/ParameterRoute";
import { SubscriptionsList } from "../views/Subscriptions/SubscriptionsList";
//import { SubscriptionsList } from "../views/Subscriptions/SubList";
import { SubscriptionItem } from "../views/Subscriptions/SubscriptionItem";
import { NewSubscriptionPage } from "../views/Subscriptions/NewSubscriptionPage";
import { HomePage } from "../views/Home/Home";
import MainRouteConsts from "../views/MainRouteConsts";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { TopBar } from "./TopBar";
import { UnauthenticatedInfo } from "./UnauthenticatedInfo";
export const MainLayout = (): ReactElement => {
  return (
    <BrowserRouter>
      <TopBar />
      <AuthenticatedTemplate>
        <Switch>
          <Route path={MainRouteConsts.Home} exact={true} component={HomePage} />
          <Route path={MainRouteConsts.Subscriptions} exact={true} component={SubscriptionsList} />
          <ParameterRoute path={MainRouteConsts.Subscriptions} parameters=':id' component={SubscriptionItem} />
          <Route path={MainRouteConsts.NewSubscription} exact={true} component={NewSubscriptionPage} />
          <Route path={MainRouteConsts.Subscriptions} exact={true} component={SubscriptionsList} />
        </Switch>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <UnauthenticatedInfo />
      </UnauthenticatedTemplate>
    </BrowserRouter>
  );
};
