import { ReactElement, useCallback, useState } from "react";
import { TypeColumn } from "@inovua/reactdatagrid-community/types";
import { createPageParameters, DataGrid, LoadDataProps, TypeColumnRender } from "../../components/grid/DataGrid";
import { Subscription } from "../../common/api/chargify/models/subscription";
import { SubscriptionsApi } from "../../common/api/chargify/subscriptionsApi";
import { StatsApi } from "../../common/api/chargify/statsApi";
import { DateFormatter } from "../../utils/dateFormatter";
import { Box, Container, TextField, Stack, Button } from "@mui/material";
import { StateChip } from "./StatusChip";

import { useHistory } from "react-router";
import MainRouteConsts, { MainRouteCrumbs } from "../MainRouteConsts";
import { BreadcrumbBuilder, Crumb } from "../../components/misc/BreadcrumbBuilder";
import "@inovua/reactdatagrid-community/index.css";

function FormateDate(date: string | null): string {
  return date ? DateFormatter.toShortDateFromString(date) : "-";
}
const columns: TypeColumn[] = [
  {
    name: "id",
    header: "ID",
    minWidth: 150,
    maxWidth: 150,
    defaultFlex: 1,
    sortable: false,
  },
  {
    name: "state",
    header: "State",
    minWidth: 100,
    maxWidth: 100,
    textAlign: "center",
    defaultFlex: 1,
    sortable: false,
    render: ({ data }: TypeColumnRender<Subscription>) => <StateChip state={data.state} />,
  },
  {
    name: "created_at",
    header: "Created",
    minWidth: 100,
    maxWidth: 110,
    defaultFlex: 1,
    sortable: false,
    render: ({ data }: TypeColumnRender<Subscription>) => FormateDate(data.created_at),
  },
  {
    name: "activated_at",
    header: "Activated",
    minWidth: 100,
    maxWidth: 110,
    defaultFlex: 1,
    sortable: false,
    render: ({ data }: TypeColumnRender<Subscription>) => FormateDate(data.activated_at),
  },
  {
    name: "expires_at",
    header: "Expires",
    minWidth: 100,
    maxWidth: 110,
    defaultFlex: 1,
    sortable: false,
    render: ({ data }: TypeColumnRender<Subscription>) => FormateDate(data.expires_at),
  },
  {
    name: "customer.organization",
    header: "Customer",
    minWidth: 100,
    defaultFlex: 1,
    sortable: false,
    render: ({ data }: TypeColumnRender<Subscription>) => data.customer.organization,
  },
  {
    name: "product.name",
    header: "Product",
    minWidth: 100,
    defaultFlex: 1,
    sortable: false,
    render: ({ data }: TypeColumnRender<Subscription>) => data.product.name,
  },
  {
    defaultFlex: 1,
    maxWidth: 50,
    textAlign: "center",
    sortable: false,
    cellSelectable: false,
  },
];

const crumbs: Crumb[] = [MainRouteCrumbs.Home];
//const MaximumItems = 100000;

interface FilteredLoadDataProps extends LoadDataProps {
  searchValue?: string;
}

async function filteredLoadData({
  skip,
  limit,
  sortInfo,
  searchValue,
}: FilteredLoadDataProps): Promise<{ data: Subscription[]; count: number }> {
  const api = new SubscriptionsApi();
  const statsApi = new StatsApi();

  const { page, itemsPerPage, sort } = createPageParameters(skip, limit, sortInfo);
  const siteData = await statsApi.getSiteData();
  const result = await api.getSubscriptions(page, itemsPerPage, sort, searchValue);

  // Since Chargify doesnt return a count, we either use the total_subscriptions count taken from states
  // OR we work it out correctly on the last page (as searchValue makes a difference)
  const maxItems =
    result.items.length < itemsPerPage ? (page - 1) * itemsPerPage + result.items.length : siteData.stats.total_subscriptions;

  return { data: result.items, count: maxItems };
}

// interface SubscriptionsListProps {
//   searchQuery?: string;
// }

export const SubscriptionsList = (): ReactElement => {
  const [filterQuery, setFilterQuery] = useState<string | undefined>();
  const [searchFieldValue, setSearchFieldValue] = useState<string | undefined>();
  const dataSource = useCallback(internalLoadDataSource, [filterQuery]);
  const history = useHistory();

  function internalLoadDataSource(props: FilteredLoadDataProps): Promise<{ data: Subscription[]; count: number }> {
    return filteredLoadData({ searchValue: filterQuery, ...props });
  }

  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        minHeight: "100%",
        pt: 3,
      }}
    >
      <Container sx={{ paddingBottom: 1 }}>
        <BreadcrumbBuilder current='Subscriptions' crumbs={crumbs} />
      </Container>
      <Container
        sx={{
          paddingBottom: 2,
        }}
      >
        <Stack spacing={1} direction='row'>
          <TextField
            id='search'
            label='Search'
            variant='standard'
            value={searchFieldValue}
            onChange={(event) => {
              setSearchFieldValue(event.target.value);
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter") setFilterQuery(searchFieldValue);
            }}
          />
          <Button
            variant='text'
            size='small'
            onClick={() => {
              setFilterQuery(searchFieldValue);
            }}
          >
            Apply
          </Button>
          <Button
            variant='text'
            size='small'
            onClick={() => {
              setSearchFieldValue("");
              setFilterQuery("");
            }}
          >
            Clear
          </Button>
        </Stack>
      </Container>
      <Container>
        {/* <ReactDataGrid
        idProperty='id'
        columns={columns}
        style={{ minHeight: 500, minWidth: 800 }}
        pagination
        defaultLimit={10}
        // defaultSkip={defaultPageSize}
        dataSource={dataSource}
        defaultFilterValue={defaultFilterValues}
        enableSelection={true}
        onSelectionChange={(x) => console.log(x)}
        onFilterValueChange={() => {
          console.log("Filter Change");
        }}
        //{...rest}
      /> */}
        <DataGrid<Subscription>
          idProperty='id'
          columns={columns}
          dataSource={dataSource}
          onSelectionChange={(item) => {
            history.push(`${MainRouteConsts.Subscriptions}/${item.id}`);
          }}
        />
        <Button href={MainRouteConsts.NewSubscription}>New</Button>
      </Container>
    </Box>
  );
};

export default SubscriptionsList;
