import { format } from "date-fns";

export class DateFormatter {
  private static shortDateTimeFormat = "yyyy/MM/dd HH:mm:ss";
  private static shortDateFormat = "yyyy/MM/dd";

  /*
   * Returns short date format string
   */
  public static toShortDateFromString(dateAsString: string): string {
    return this.toShortDate(new Date(dateAsString));
  }

  /*
   * Returns short date format string
   */
  public static toShortDate(date: Date): string {
    return format(new Date(date), this.shortDateFormat);
  }

  /*
   * Returns short date/time format string
   */
  public static toShortDateTimeFromString(dateAsString: string): string {
    return this.toShortDateTime(new Date(dateAsString));
  }

  /*
   * Returns short date/time format string
   */
  public static toShortDateTime(date: Date): string {
    return format(new Date(date), this.shortDateTimeFormat);
  }
}
