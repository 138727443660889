import { ReactElement, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Container, Typography, Skeleton, TextField, Snackbar, Alert } from "@mui/material";
import { Subscription } from "../../common/api/chargify/models/subscription";
import { SubscriptionsApi } from "../../common/api/chargify/subscriptionsApi";
import PageLoading from "../../components/loading/PageLoading";
import { CustomerCard } from "../../components/card/CustomerCard";
import { MainRouteCrumbs } from "../MainRouteConsts";
import { EditSubscriptionDetailsCard } from "./EditSubscriptionDetailsCard";
import { BreadcrumbBuilder, Crumb } from "../../components/misc/BreadcrumbBuilder";
import { useSnackbar } from "notistack";

// interface SubscriptionItemProps {
//   id: string;
// }

export const SubscriptionItem = (): ReactElement => {
  const { id } = useParams<{ id: string }>();
  const [subscription, setSubscription] = useState<Subscription>();
  const [updating, setUpdating] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const api = new SubscriptionsApi();

  useEffect(() => {
    api.getSubscription(id).then((result) => {
      setSubscription(result);
    });
  }, []);

  function handleUpdate(activatedDate: Date | null, expiresDate: Date | null) {
    setUpdating(true);
    if (activatedDate == null) {
      alert("Activated at date cannot be null");
    } else {
      api
        .overrideActivatedExpires(id, activatedDate, expiresDate)
        .then(() => {
          enqueueSnackbar(`Subscription ${id} successfully updated.`, { variant: "success" });
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar(`An error occurred updating ${id}: ${error} ${error.error}`, { variant: "error" });
        })
        .finally(() => setUpdating(false));
    }
  }

  const crumbs: Crumb[] = [MainRouteCrumbs.Home, MainRouteCrumbs.Subscriptions];

  return (
    <>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          pt: 3,
        }}
      >
        <Container sx={{ paddingBottom: 1 }}>
          {subscription ? (
            <BreadcrumbBuilder current={subscription.id.toString()} crumbs={crumbs} />
          ) : (
            <Skeleton height={300} />
          )}
        </Container>
        <Container>{subscription ? <CustomerCard customer={subscription.customer} /> : <Skeleton height={300} />}</Container>
        <Container maxWidth='lg'>{/* <EditSubscriptionDetailsCard subscription={subscription} /> */}</Container>
      </Box>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container>
          {subscription ? (
            <EditSubscriptionDetailsCard subscription={subscription} updating={updating} onUpdate={handleUpdate} />
          ) : (
            <Skeleton height={300} />
          )}
        </Container>
      </Box>
    </>
  );
};
