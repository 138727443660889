import { Crumb } from "../components/misc/BreadcrumbBuilder";

export class MainRouteConsts {
  public static readonly Home = "/";
  public static readonly Subscriptions = "/subscriptions";
  public static readonly NewSubscription = "/subscriptions/new";
}

export class MainRouteCrumbs {
  public static readonly Home: Crumb = { title: "Home", href: MainRouteConsts.Home };
  public static readonly Subscriptions: Crumb = { title: "Subscriptions", href: MainRouteConsts.Subscriptions };
}

export default MainRouteConsts;
